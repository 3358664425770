import { Injectable } from '@angular/core';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { SBRAItem } from '../../model/sbra-item';

@Injectable({
    providedIn: 'root',
})
export class RiskMatrixService {
    // tslint:disable: max-line-length
    constructor(private apiService: HiramAPIService) {}

    frequencies = [
        {
            range: 'A',
            title: 'Very Likely',
            detailItems: [
                'Similar scenario has occurred once or more at site in the last 10 years',
                'Has happened several times at site or many times in company',
            ],
            quantitativeInterpretation: '0.1 to 1 - Mid-point at 0.3',
        },
        {
            range: 'B',
            title: 'Somewhat Likely',
            detailItems: ['Has happened before at site or several times in company'],
            quantitativeInterpretation: '0.01 to 0.1 - Mid-point at 0.03',
        },
        {
            range: 'C',
            title: 'Unlikely',
            detailItems: ['Has not happened before at site or has happened a few times in company'],
            quantitativeInterpretation: '0.001 to 0.01 - Mid-point at 0.003',
        },
        {
            range: 'D',
            title: 'Very Unlikely',
            detailItems: ['Have been isolated occurrences in company or has happened several times in industry'],
            quantitativeInterpretation: '0.0001-0.001 - Mid-point at 0.0003',
        },
        {
            range: 'E',
            title: 'Very Highly Unlikely',
            detailItems: [
                'Has happened once or not at all in company',
                'Has happened a few times or not at all in industry',
            ],
            quantitativeInterpretation: '< 0.0001',
        },
    ];

    consequences = [
        {
            level: 'I',
            safetyHealth: 'Fatality(ies); (Hurt Level 4 and 5); Public Life Altering Injury (Hurt Level 3)',
            environmentalImpact:
                'Widespread, Long Term, Significant Adverse Effects ; Major Emergency Response, Months to Years Clean-up',
            publicImpact:
                'Extended (>3 Months) National or International Media Coverage; Large Community Disruption or Evacuation (>1000 people); Closure of Major Transportation Route >24 Hrs.',
            financialImpact: '>$30 Million',
        },
        {
            level: 'II',
            safetyHealth: 'Workforce Life Altering Injury (Hurt Level 3); Public Moderate Hurt (Hurt Level 2)',
            environmentalImpact:
                'Localized, Medium Term, Significant Adverse Effects, Intermediate Emergency Response, Weeks to Months Clean-up',
            publicImpact:
                'National Media Coverage; Medium Community Disruption or Evacuation (100-1000 people); Closure of Major Transportation <24 Hrs.',
            financialImpact: '$3 Million – $30 Million',
        },
        {
            level: 'III',
            safetyHealth: 'Workforce Moderate Hurt (Hurt Level 2); Public Minor Hurt (Hurt Level 1)',
            environmentalImpact: 'Short Term, Minor Adverse Effects; Local Emergency Response, Days to Weeks  Clean-up',
            publicImpact:
                'Public Complaints; Small Community Disruption or Evacuation (<100 people) Closure of Secondary Transportation Route <24 Hrs.; Tier 1 PSE',
            financialImpact: '$300,000-$3 Million',
        },
        {
            level: 'IV',
            safetyHealth: 'Workforce Minor Hurt (Hurt Level 1)',
            environmentalImpact: 'Inconsequential or No Adverse Effects; Confined to Site or Close Proximity',
            publicImpact: 'Public Complaint; Temporary Closure of Minor Transportation Route; Minor Inconvenience',
            financialImpact: '<$300,000',
        },
    ];

    riskLevels = [
        { range: 'A', level: 'I', value: 1 },
        { range: 'A', level: 'II', value: 1 },
        { range: 'A', level: 'III', value: 2 },
        { range: 'A', level: 'IV', value: 3 },

        { range: 'B', level: 'I', value: 1 },
        { range: 'B', level: 'II', value: 1 },
        { range: 'B', level: 'III', value: 3 },
        { range: 'B', level: 'IV', value: 4 },

        { range: 'C', level: 'I', value: 1 },
        { range: 'C', level: 'II', value: 2 },
        { range: 'C', level: 'III', value: 3 },
        { range: 'C', level: 'IV', value: 4 },

        { range: 'D', level: 'I', value: 2 },
        { range: 'D', level: 'II', value: 3 },
        { range: 'D', level: 'III', value: 4 },
        { range: 'D', level: 'IV', value: 4 },

        { range: 'E', level: 'I', value: 3 },
        { range: 'E', level: 'II', value: 4 },
        { range: 'E', level: 'III', value: 4 },
        { range: 'E', level: 'IV', value: 4 },
    ];

    public findRiskLevel(item: SBRAItem): number {
        if (!this.itemWithSomethingDiffThenNA(item)) {
            return null;
        }

        return this.riskLevels.find(
            each => each.level === item.riskAssessmentConsequenceLevel && each.range === item.riskAssessmentFrequencyId
        ).value;
    }

    private itemWithSomethingDiffThenNA(item: SBRAItem): boolean {
        return item.riskAssessmentConsequenceLevel !== 'N/A' && item.riskAssessmentFrequencyId !== 'N/A';
    }
}
